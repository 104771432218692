import React from 'react';
import { ExtLink } from 'components';
import { Container, Box, IconButton, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import monash_logo from '../../assets/monash-logo.png';

export const ExplanatoryStatement = () => {
  return (
    <Container>
      <Box mt={8}>
        <Box display="flex" justifyContent="center">
          <img src={monash_logo} height={50} />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={() => window.close()}>
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Typography variant="h5" align="center" paragraph>
        PARENT EXPLANATORY STATEMENT
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" align="center" paragraph>
          <strong>Empowering parents in the COVID-19 mental health response: Evaluating the PiP+ program</strong>
        </Typography>
      </Box>

      <Typography component={'span'} align="left">
        <span>
          <strong>Project number: 28551</strong>
          <br />
        </span>

        <span>
          <strong>Chief Investigator: </strong> Associate Professor Marie Yap, Turner Institute for Brain and Mental
          Health, School of Psychological Sciences, Monash University. E:{' '}
          <ExtLink href="mailto:marie.yap@monash.edu">marie.yap@monash.edu</ExtLink>; Ph:{' '}
          <ExtLink href="tel:+61399050723">+61 3 990 50723</ExtLink>.
        </span>

        <br />
        <br />

        <span>
          <strong>Program manager:</strong> Dr Mairead Cardamone-Breen, Research Fellow, Monash University. E:{' '}
          <ExtLink href="mailto:mairead.cardamone-breen@monash.edu">mairead.cardamone-breen@monash.edu</ExtLink>; Ph:{' '}
          <ExtLink href="tel:0399051250">(03) 9905 1250.</ExtLink>
        </span>

        <br />
        <br />

        <span>
          You are invited to take part in a research study being conducted by Monash University. Please read this
          Explanatory Statement in full before deciding whether or not to participate in this research. If you would
          like further information regarding any aspect of this project, please contact the researchers via email (
          <ExtLink href="mailto: med-pip-plus@monash.edu">med-pip-plus@monash.edu</ExtLink>) or on{' '}
          <ExtLink href="tel:0399051250"> (03) 9905 1250</ExtLink>.
        </span>

        <br />
        <br />

        <span>
          <strong>What does the research involve?</strong>
          <br />
          This project aims to evaluate the effects of an online parenting program to support parents of teenagers
          during the COVID-19 pandemic. The program, called Partners in Parenting (PiP), was initially developed to help
          equip parents with knowledge and skills that may protect their teenage children against mental health problems
          like depression and anxiety. We have recently updated the program, PiP+, to make it more relevant for parents
          during the COVID-19 pandemic. The PiP+ program includes new, expert-endorsed recommendations for supporting
          adolescent mental health during the pandemic, as well as an optional online peer support group for parents who
          are completing the program. The peer support group is conducted via Facebook, and will be open for all parents
          who register for the PiP+ program.
        </span>

        <br />
        <br />

        <span>
          <strong>Who can participate?</strong>
          <br />
          We are inviting parents or guardians of at least one teenager aged 12 to 17 (inclusive), who reside in
          Australia, are fluent in English, and have regular access to the internet, to participate.
          <br />
          <br />
        </span>

        <span>
          <strong>What will I be asked to do?</strong>
          <br />
          If you agree to participate, you will be asked to do the following:
          <ol>
            <li>
              Go online to <ExtLink href="partnersinparenting.com.au">partnersinparenting.com.au</ExtLink>
              <br />
              Fill in the online registration and consent form, and indicate that you have read this Explanatory
              Statement and agree to take part. You will be asked to provide brief demographic information so that we
              can ensure you are eligible for the study. We will also ask you to provide a phone number that we can
              contact you on.
            </li>
            <br />
            <li>
              You will then be invited to complete an online survey assessment. The surveys will ask about your
              parenting, your confidence in parenting during COVID-19, your own mental wellbeing, and some feelings and
              behaviours in your teenager that may be associated with depression and anxiety (30-45 minutes altogether).
              If your responses to the surveys suggest that you or your teenager may be experiencing symptoms of mental
              health problems, we’ll let you know and may suggest that you seek appropriate professional support.
            </li>
            <br />
            <li>
              As soon as you complete the online surveys, you will get access to the PiP+ program, including:
              <br />
              <ol type="a">
                <li>
                  A <strong>personalised feedback report</strong>, outlining your parenting areas of confidence,
                  strengths, and areas for improvement, as well as a copy of the evidence-based parenting guidelines{' '}
                  <i>
                    Reducing teenagers’ risk of depression and anxiety disorders: Strategies for parents during the
                    COVID-19 pandemic.
                  </i>
                </li>
                <li>
                  Access to up to <strong>10 interactive online modules</strong>, including one module about parenting
                  during COVID- 19. You will be recommended certain modules based on your survey responses, however you
                  can choose to complete any of the 10 modules. Each module may take between 15-25 minutes to complete,
                  depending on the topic and the way you choose to engage with it. We suggest that you complete one
                  module per week, but if you prefer, you can complete them any time you like. We’ll send you reminders
                  each week, by email, and by SMS if you prefer.
                </li>
                <li>
                  [optional] Access to an <strong>online peer support group</strong>, via Facebook. While you’re
                  completing the program, you’ll also receive access to a private, invitation-only peer support group
                  with other parents completing the PiP+ program. The group aims to provide a safe, supportive space for
                  parents to connect, learn from each other, share experiences, and apply what they’ve learned in the
                  PiP+ program. The group will also be moderated and facilitated by members of the PiP+ team, who will
                  provide regular expert advice, share new content (including updated content based on topics raised in
                  the group), and facilitate discussions on various topics. There will be one large group, as well as
                  smaller topic groups which you can join if you wish.
                  <br />
                  If you choose to join the peer support group, we will provide you with more information, including
                  details of what happens with the information you share, when you join. There will also be community
                  guidelines to keep everyone safe, which we’ll expect all members to follow.
                </li>
              </ol>
            </li>
            <br />
            <li>
              At the end of your program (about 3-months after the first survey), we will invite you to complete another
              online survey. This survey will ask the same questions as the first survey, as well as some questions
              about your experience of the PiP+ program. The survey will take approx. 30-45mins to complete, and is
              optional.
            </li>
            <br />
            <li>
              [optional] We <i>may</i> also invite you to participate in an optional interview with one of the
              researchers, about 6 months after your first survey. The interviews will be conducted via phone or online
              using Zoom video conferencing. The purpose of the interview is to hear your experience of participating in
              the PiP+ program and/or online peer support group (if you choose to join). We will ask you what you liked
              and didn’t like about the program/peer support groups, how supported you felt, whether you think your
              parenting, your wellbeing or your teen’s wellbeing has improved, and any suggestions you have for
              improving the program in future. <br />
              We will record the interviews, by recording the Zoom call or using audio recording for phone calls. The
              interviews will then be transcribed, by a reputable transcription company, for analysis. We will email you
              a copy of this transcription for you to check whether it accurately reflects your opinions. We will give
              you two weeks to review the transcript and let us know if you would like any changes.
            </li>
          </ol>
        </span>

        <span>
          All parts of this study can be completed at any time or place of convenience to you, as long as there is
          internet access. The time commitment will depend on whether you join the peer support groups, and how much
          time you choose to spend on the PiP+ program. In total, participation could take between 2.5 to 6 or more
          hours (if you do the full program), over a period of 3 to 6 months.
        </span>

        <br />
        <br />

        <span>
          <strong>
            Agreeing to participate and change of mind
            <br />
          </strong>
          Participation in this research is completely voluntary. If you decide to take part, you will be asked to check
          the box in the online consent form on our website. If you change your mind at any stage, you are free to
          withdraw from the research (e.g., you may stop completing the modules or surveys, leave the peer support
          group, and/or request that we do not contact you further). You may also request that your data (i.e. survey
          responses, posts and comments in the peer support group) be withdrawn once submitted, prior to the final
          report being written. After this time (around April 2022), you will not be able to withdraw your data.
        </span>

        <br />
        <br />

        <span>
          <strong>Source of funding</strong>
          <br />
          This project is funded by the National Health and Medical Research Centre (NHMRC), Department of Health
          Australia.
        </span>

        <br />
        <br />

        <span>
          <strong>What are the benefits?</strong>
          <br />
          This project is designed to help equip parents with parenting strategies that may reduce the risk of
          depression and anxiety in their teenagers, with additional focus on supporting teenagers during and after
          COVID-19. The program also aims to support parents, via peer support, in order to improve their own mental
          wellbeing. In the longer term, it is hoped that the program may reduce future mental health problems in
          adolescents.
          <br />
          <br />
        </span>

        <span>
          <strong>Are there any risks?</strong>
          <br />
          The risks of participating in this research are low; however we have identified the following potential risks:
          <ol>
            <li>
              Although unlikely, it is possible that you may become upset while completing the surveys, receiving your
              parenting program, or participating in the peer support groups. If this happens, please let the
              researchers know, and you are welcome to withdraw from part or all of the study at any time (i.e. you can
              choose to leave the peer support groups but remain in the PiP+ program, or leave the entire program). If
              you get upset or distressed, please seek support from a family member or friend, or call one of the
              helplines below:
              <Box m={2}>
                <strong>
                  <i>
                    Lifeline: <ExtLink href="tel:13 11 14">13 11 14</ExtLink> (24 hours a day, 7 days a week)
                    <br />
                    Beyond Blue: <ExtLink href="tel:1300 224 636">1300 224 636</ExtLink> (24 hours a day, 7 days a week){' '}
                    <br />
                    Parentline: <ExtLink href="tel:13 22 89">13 22 89</ExtLink> (8am – midnight, 7 days a week)
                    <br />
                  </i>
                </strong>
              </Box>
            </li>

            <li>
              Although unlikely, there is also a risk to your privacy through breaches of confidentiality, for either of
              the following reasons:
              <ol type="a">
                <li>
                  If you disclose identifying information about yourself or your family in the online peer support
                  groups. We will ask you not to do this and will remove any posts containing identifying information.
                </li>
                <li>
                  If the researchers deem there is a risk of harm to yourself or others that cannot be prevented without
                  breaching confidentiality. If we believe that yourself, your child, or someone else is at risk of harm
                  (e.g., child abuse, self-harm), we are bound by professional codes of ethics to take reasonable action
                  to prevent this harm occurring, even if it means breaching confidentiality. If this is necessary, we
                  will try to discuss the situation with you first.
                </li>
              </ol>
            </li>
          </ol>
        </span>

        <span>
          <strong>Reimbursement (optional interviews only)</strong>
          <br />
          If you are invited to the optional interviews, and you choose to take part, we will reimburse you with a $25
          Prezzee gift voucher. You can redeem the voucher at hundreds of retailers, including Coles, Woolworths, Kmart,
          Target, Amazon, Ikea, Uber Eats, and many more.
        </span>

        <br />
        <br />

        <span>
          <strong>What about privacy?</strong>
          <br />
          Information collected in this project will only be accessible by the researchers named on the project. The
          only exception to this is transcriptions of interviews (if you choose to take part) which will be conducted by
          a reputable and trustworthy third-party transcription service. The service will only have access to the
          interview recording while they are completing the transcription, after which the files will be deleted.
          Various third-party services are used as part of the PiP+ website and program, to capture the data entered
          into the website, and communicate with you via email and SMS. These services all adhere to strict data
          security requirements to ensure safety and privacy of your data. If you choose to join the optional peer
          support group on Facebook, we will extract certain information from the groups, including posts and comments.
          You will be provided with more information about this before you join the groups. When we store your personal
          data, we separate this from any identifying information about you, to protect your privacy. All electronic
          files are password protected. Any written reports will only include group data, so you will not be
          identifiable in any way. We will also make non-identifiable questionnaire data (i.e. responses to survey
          questions, with no other information about you) available to other researchers via an online data repository
          such as Bridges.
        </span>

        <br />
        <br />
        <span>
          <strong>Storage and use of data for other purposes</strong>
          <br />
          Electronic data, kept separately from any personal information about you, will be kept indefinitely. All data
          will be stored securely on Monash University servers, password protected, and only accessible by approved
          researchers. De-identified data (i.e. where you cannot be identified) may be used for future research, with
          ethics approval.
        </span>

        <br />
        <br />

        <span>
          <strong>How can I see the results? </strong>
          <br />A summary of the results will be available in mid-to-late <strong>2022</strong>. If you would like a
          copy, please contact Dr Mairead Cardamone-Breen at{' '}
          <ExtLink href="mailto:mairead.cardamone-breen@monash.edu">Mairead.cardamone-breen@monash.edu</ExtLink>. We
          will also publish the results in scientific journals.
        </span>

        <br />
        <br />

        <span>
          <strong>What if I have any complaints or concerns? </strong>
          <br />
          This project has been approved by the Monash University Human Research Ethics Committee. Should you have any
          concerns or complaints about the conduct of the project, you are welcome to contact the Executive Officer,
          Monash University Human Research Ethics Committee (MUHREC):
          <Box mt={2}>
            Executive Officer
            <br />
            Monash University Human Research Ethics Committee (MUHREC)
            <br />
            Room 111, Chancellery Building D,
            <br />
            26 Sports Walk, Clayton Campus
            <br />
            Research Office
            <br />
            Monash University VIC 3800
            <br />
            Tel: <ExtLink href="tel: +61399052052">+61 3 9905 2052</ExtLink>
            <br />
            Email: <ExtLink href="mailto: muhrec@monash.edu">muhrec@monash.edu</ExtLink>
            <br />
            Fax: <ExtLink href="tel: +61399053831">+61 3 9905 3831</ExtLink>
            <br />
          </Box>
        </span>

        <br />

        <span>
          Thank you, <br />
          <strong>Associate Professor Marie Yap</strong>
          <br />
        </span>

        <br />

        {/* add signature  */}

        <span>
          Associate Professor (Research) and Psychologist <br />
          Head, Parenting and Youth Mental Health Group <br />
          Turner Institute for Brain and Mental Health <br />
          School of Psychological Sciences
          <br />
          Monash University
          <br />
        </span>
      </Typography>

      <Box pb={8} />
    </Container>
  );
};
